<template>
    <div style="padding-bottom:80px">

        <div class="info">
            <div class="info-img" :style="{backgroundImage: 'url(' + require('../../assets/img/' + infoPic) + ')'}"></div>
            <ul>
                <li>SIM卡号：{{arrs.msisdn}}</li>
                <li>ICCID：{{arrs.iccid}}</li>
                <li>套餐名称：{{arrs.packName}}</li>
            </ul>
        </div>
        <div class="orderForm">
            <van-cell-group>
                <van-cell title="套餐详情" icon="description" value=""/>
                <van-cell title="卡片状态">
                    <van-tag slot="default" size="medium" plain :type="cardStatus.type">{{cardStatus.label}}</van-tag>
                </van-cell>
                <van-cell title="剩余可使用流量" :value="`${arrs.periodCanUsage - arrs.usageAmount}M`"/>
                <van-cell title="套餐开始时间" :value="`${arrs.periodStartTime}`"/>
                <van-cell title="套餐结束时间" :value="`${arrs.periodEndTime}`"/>
                <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="valueWay"
                    label="生效方式"
                    placeholder="点击选择"
                    @click="showPicker = true"
                    />
                    <van-popup v-model="showPicker" position="bottom">
                    <van-picker
                        show-toolbar
                        value-key="label"
                        :columns="ways"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                    />
                </van-popup>
                <!-- <van-cell title="加油包系数" :value="`${discount}`"/> -->
                <van-cell title="加油包单价" :value="`￥${(form.price * discount).toFixed(4)}元`"/>
                <van-field name="stepper" label="数量">
                    <template #input>
                        <van-stepper v-model="form.period" :min="1" :max="99" integer/>
                    </template>
                </van-field>
                
            </van-cell-group>
            <div class="mySwipe">
                <div class="swipeTitle">请确认套餐时间内流量是否够用（左右滑动选择套餐）</div>
                <van-swipe :loop="false" :width="250" :show-indicators=false @change="onChange">
                    <van-swipe-item v-for="(it,i) of pack" :key="i">
                        <div class="swipeDiv" :class="{active:current == i}">
                            <div>{{it.name}}</div>
                            <div style="margin-top:10px">有效期：</div>
                            <div>{{it.startDate}} 至 {{it.endDate}}</div>
                        </div>
                    </van-swipe-item>
                 
                </van-swipe>
            </div>
            <van-cell-group>

            </van-cell-group>

            <van-submit-bar v-if="flag"
                :price="price * 100"
                button-text="提交订单"
                @submit="onSubmit">
            </van-submit-bar>

            <van-submit-bar v-if="!flag"
                disabled
                tip="暂无加油包，请联系上级添加"
                tip-icon="info-o"
                price="0"
                button-text="提交订单"
                @submit="onSubmit">
            </van-submit-bar>

        </div>
    </div>
</template>
<script>
import { Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    name:'refueingCharge',
    data() {
        return {
            valueWay:'',
            discount:1,
            value: '',
            columns: ['立即生效', '次日生效', '次月生效'],
            showPicker: false,
            flag:true,
            infoPic: 'cmcc.png',
            arrs:{},
            form:{},
            pack:[],
            cardStatus:{
                type:'success',
                label:'正常'
            },
            current:0,
            chargeOrderId:'',
            timer:Object,
            way:-1,
            ways:[]
        }
    },
    computed:{
        price(){
            return this.form.period * this.form.price * this.discount || 0
        },
    },
    methods: {
        onConfirm(value, index) {
            this.valueWay = value.label
            this.way = value.value
            this.form.way = value.value
            this.setDiscount()
            this.showPicker = false
        },
        setDiscount(){
            if(this.way != -1 && this.form.addType){
                this.$api.getRefueingDiscount({addType:this.form.addType,way:this.way}).then(res => {
                    if(res.data.code ==0){
                        this.discount = res.data.data.discount
                    }
                })
            }
        },
        payFn(d){
                if (!this.$store.state.wxSDKReady) {
                    setTimeout(this.payFn, 1000);
                    return;
                }
                this.checkHasPay();
                wx.chooseWXPay({
                    timestamp: d.data.timestamp,
                    nonceStr:  d.data.nonceStr,
                    package:   d.data.packAge,
                    signType: "MD5",
                    paySign:   d.data.sign,
                    success: res => {

                    }
                });
        },
        createAccChargeOrder(){
            let params = {amount:this.price,
            // orderForm:1,
            payWay:'weixin-official'};
            this.$api.createAccChargeOrder(params).then(res => {
                if(res.data.code ==0){
                    this.chargeOrderId = res.data.data.orderId
                    this.createAccChargeOrderPay();
                }
            })
        },
        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        /**
         * 创建账户充值订单
         * @param chargeOrderId
         */
        createAccChargeOrderPay2(){
            Toast.loading({duration: 0,mask: true, forbidClick: true });
            let params = {orderId:this.chargeOrderId, payWay:'weixin-official',openId:localStorage.openId};
            this.$api.createOrderPay2(params).then(res => {
                Toast.clear();
                let d = res.data;
                if (d.code !== 0) {
                    Toast(d.msg);
                    return;
                }
                this.payFn(d);
            });
        },

        checkHasPay(){
            let check = async () =>{
                let params = {chargeOrderId:this.chargeOrderId};
                Toast.loading({duration: 0,mask: true, forbidClick: true });
                let res = await this.$api.queryHasPayed(params);
                //已经支付
                if(!!res.data.data){
                    Toast.clear();
                    // clearInterval(a);
                    if(localStorage.groupClientFlag == '1'){
                        Toast.success('充值成功');
                        this.$router.push({name:'payRefueing'}); 
                    }else{
                        this.createRefueingOrder();
                    }
                } else {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    await check();
                }
            }
            check();
        },
        
        createRefueingOrder(){
            let params ={
                // msisdns:[this.arrs.msisdn],
                msisdn:this.arrs.msisdn,
                packId:this.pack[this.current].id,
                period:this.form.period,
                orderFrom:1,
                way:this.form.way,
                type:this.form.addType,
                openId:localStorage.openId,
                chargeOrderId:this.chargeOrderId
            }
            this.$api.addRefueing(params).then(res => {
                if(res.data.code == 0){
                    if(localStorage.groupClientFlag == '1'){
                        this.chargeOrderId = res.data.data
                        this.createAccChargeOrderPay2()
                    }else{
                         localStorage.refueingOrderId = res.data.data;
                        // localStorage.removeItem('refueing');
                        Toast.success('充值成功');
                        this.$router.push({name:'payRefueing'});
                    }
                   
                }
            })
        },
        onSubmit(){
            if(this.way == -1){
                Toast("请选择生效方式");
                return;
            }
            localStorage.refueing = JSON.stringify({
                msisdn:this.arrs.msisdn,
                iccid:this.arrs.iccid,
                packName:this.pack[this.current].name,
                period:this.form.period,
                packId:this.pack[this.current].id,
                firstPrice:this.form.price * this.discount,
                date:this.pack[this.current].date,
                discount:this.discount,
                price:this.price,
                startDate:this.pack[this.current].startDate,
                endDate:this.pack[this.current].endDate,
                flow:this.pack[this.current].availableFlow
            });
            this.$dialog.confirm({
                title: '充值加油包',
                message: '您确定充值加油包吗？充值加油包只对当前卡片时间不变，流量增加？'
            }).then(() => {
                let params ={
                    msisdns:[this.arrs.msisdn],
                    packId:this.pack[this.current].id,
                    period:this.form.period,
                    orderFrom:1,
                    way:this.form.way,
                    type:this.form.addType
                }
                // this.$router.push({name:'payRefueing'});
                this.$api.refueingOrderEstablish(params).then(res => {
                    // if(res.data.code == 0)this.createAccChargeOrder();
                    if(res.data.code == 0){
                        if(localStorage.groupClientFlag == '1'){
                            this.createRefueingOrder()
                        }else{
                            this.createAccChargeOrder();
                        }
                    }
                })
                
            }).catch(() => {
                // on cancel
            });
        },
        onChange(index) {
            this.current = index;
            this.form.price = this.pack[index].price
            this.form.addType = this.pack[index].type
            this.$api.getPackageConfigUse({addType:this.form.addType}).then(res => {
                if(res.data.code == 0){
                    this.ways = res.data.data
                    this.discount = 1
                    this.way = -1
                    this.valueWay = ''
                    // this.setDiscount()
                }
                
            })
        }
    },
    created() {
        this.$store.commit('setPageTitle', "充值加油包");
        if(!localStorage.message){
            this.$api.getCardInfo({cardNumber:localStorage.msisdn}).then(res => {
                if(res.data.code == 0){
                    
                    this.arrs = Object.assign({},res.data.data)
                    localStorage.message = JSON.stringify(this.arrs)
                     this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
                }
            })
        }else{
            this.arrs = JSON.parse(localStorage.message)
             this.cardStatus = this.$returnInfo(this.$store.state.dictionaries.cardStatus, this.arrs.status)
        }
        this.$api.getRefueingInfo({msisdn:localStorage.msisdn}).then(res => {
            if(res.data.code == 0){
                if(res.data.data.length == 0)this.flag = false;
                this.pack = res.data.data
                let date = new Date();
                this.pack.forEach(it => {
                    it.startDate = `${date.getFullYear()}-${date.getMonth()+1}-01`
                    it.endDate = this.$dateAdd(it.period - 1, new Date())
                })
                let price = !!this.pack[0] ? this.pack[0].price : 0
                this.form = {
                    price:price,
                    minPeriod:1,
                    period:1,
                    addType:this.pack[0].type
                }
                this.$api.getPackageConfigUse({addType:this.form.addType}).then(res => {
                    if(res.data.code == 0){
                        this.ways = res.data.data
                    }
                    
                })
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.info{
    width: 750px;
    height: 255px;
    background-color: #209df2;
    &-img{
        float: left;
        width: 195px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 134px;
    }
    ul{
        float: left;
        margin: 38px 0 0 18px;
        width: 520px;
        li{
            color: #fff;
            font-size: 26px;
            text-align: left;
            height: 61.5px;
            line-height: 61.5px;
        }
    }
}
.orderForm{
    text-align: left;
    .van-submit-bar{
        border-top:1px solid #ddd;
    }
}

.mySwipe{
    width: 90%;margin: 0 auto;font-size: 24px;font-weight: 600;
    .swipeTitle{
        color: #EB5E00;margin: 20px 0px 0 24px ;
        font-size: 22px;
    }
    .swipeDiv{
        text-align: center;padding: 20px 0;
        border:1px solid #ddd;
        margin: 5%;
        
    }
    .active{
        color: #EB5E00;
        border:1px solid sandybrown;
    }
}

</style>